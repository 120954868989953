import React from 'react'
import PropTypes from 'prop-types'
import config from 'config'
import styled from '@emotion/styled'
import LeadForm from '../LeadForm'
import { Form } from '../LeadForm/LeadForm.styles'



// loading default JSON for lead forms
import contactFormDataJSON from './contactFormData.json'

//load local JSON for setup
import configJSON from '../ConfigForForms/config.json'

const StyledForm = styled(Form)`
  grid-template-columns: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
    > div {
      &:nth-of-type(7n + 0),
      &:nth-of-type(8n + 0) {
        display: flex;
        flex-direction: column;
        grid-column: 1 / 3;
      }
    }
  }
`
//var t = JSON.parse(configJSON);
//alert(t['albertaEbillJS'])

var albertaEbillJS = configJSON.albertaEbillHTML;
var albertaEbillCSS = configJSON.albertaEbillCSS;

/*
formIOUrl="https://forms.justenergy.com/justenergy/albertaEBill/albertaEBill.js"
    formIOCSSUrl = "https://forms.justenergy.com/justenergy/albertaEBill/albertaEBill.css"
*/
const AlbertaEBillForm = ({ domain, brandToken }) => (
  <LeadForm
    formGUID={config.albertaEBillFormGUID}
    labelType="placeholder"
    domain={domain}
    brandToken={brandToken}
    successMessage="Thank you for your interest in our products and services! One of our specialists will be contacting you shortly."
    errorMessage="We're sorry, something went wrong with our form. Please try again later."
    as={StyledForm}
    inlineFormData={JSON.stringify(contactFormDataJSON)}
    formIOUrl={configJSON.albertaEbillHTML}
    formIOCSSUrl = {configJSON.albertaEbillCSS}
  />
)

AlbertaEBillForm.propTypes = {
  domain: PropTypes.string.isRequired,
  brandToken: PropTypes.string.isRequired,
}

export default AlbertaEBillForm
