import styled from '@emotion/styled'
import { stripUnit } from 'polished'
import { css } from '@emotion/core'

export const Columns = styled.div(
  ({ theme, numberOfChildren, cardStyled, centeredContent }) => css`
    grid-template-columns: repeat(
      auto-fit,
      minmax(${stripUnit(theme.breakpoints.xs) / 2}px, 1fr)
    );
    grid-gap: ${theme.spacing.lg};

    > div {
      > .svg-image {
        margin-bottom: ${theme.spacing.md};
        margin-left: auto;
        margin-right: auto;
      }

      margin-bottom: ${theme.spacing.lg};

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    @media (min-width: ${theme.breakpoints.lg}) {
      display: grid;

      > div {
        margin-bottom: 0;
      }
    }

    ${numberOfChildren > 4 && `grid-template-columns: repeat(3, 1fr)`};

    ${cardStyled &&
    `
      > div {
        padding: ${theme.spacing.md};
        color: ${theme.colors.dark.base.hex};
        background-color: ${theme.colors.light.lighter.hex};
        box-shadow: ${theme.shadow};

        *:not(a):not(button) {
          color: inherit;
        }
      }
    `}

  
      > div {
        text-align: center;

        .svg-image {
          margin-left: auto;
          margin-right: auto;
        }
      }
  
  `
)
