import React from 'react'
import PropTypes from 'prop-types'
import Img from '../Image'

const Column = ({ children, image, ...props }) => (
  <div {...props}>
    {image && <Img data={image} hasMarginBottom />}
    {children}
  </div>
)

Column.defaultProps = {
  image: null,
}

Column.propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.object,
  }),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.objectOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}

export default Column
