import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Styled from './Columns.styles'

const Columns = ({ children, cardStyled, centeredContent, ...props }) => {
  const [centered, setCentered] = useState(centeredContent)

  useEffect(() => {
    const childrenContentAmount = React.Children.map(
      children,
      child => child.props.centeredContent
    )

    if (childrenContentAmount.includes(true)) {
      setCentered(false)
    }
  }, [])

  return (
    <Styled.Columns
      numberOfChildren={children.length}
      cardStyled={cardStyled}
      centeredContent={centered}
      {...props}
    >
      {children}
    </Styled.Columns>
  )
}

Columns.defaultProps = {
  cardStyled: false,
  centeredContent: true,
}

Columns.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  cardStyled: PropTypes.bool,
  centeredContent: PropTypes.bool,
}

export default Columns
