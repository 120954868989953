import React from 'react'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Section from '../../../../components/Section'
import Hero from '../../../../components/Hero'
import Columns from '../../../../components/Columns'
import Column from '../../../../components/Columns/Column'
import Typography from '../../../../components/Typography'
import styled from '@emotion/styled'

import { CheckCircle } from 'emotion-icons/material'
import AlbertaEBillForm from '../../../../components/AlbertaEBillForm'
import Layout from '../../../../layouts/index'

const StyledUL = styled.ul`
  list-style-type: none;
  text-align: left;
  margin-top: 25px;

  li {
    margin-bottom: 15px;
  }

  svg {
    margin-right: 15px;
  }
`

const EBillPage = ({ data }) => {
  const {
    site: {
      siteMetadata: { siteUrl, brandToken },
    },
    file,
  } = data
  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en-US' }}>
        <title>Contact Us</title>
        <meta name="description" content="Contact Just energy Today" />
      </Helmet>
      <main>
        <Hero
          image={{
            childImageSharp: file.childImageSharp,
            description: 'Green gradient background',
          }}
          darkText={false}
        >
          <Typography variant="h1">Alberta E-Bill</Typography>
          <Typography variant="h4" rendersAs="h2">
            Manage your bills on your own terms and at your own convenience.
          </Typography>
        </Hero>
        <Section>
          <Columns>
            <Column>
              <Typography variant="h3">
                With Just Energy&apos;s e-Bill
              </Typography>
              <StyledUL>
                <li>
                  <CheckCircle />
                  Reduce paper clutter and help the environment
                </li>
                <li>
                  <CheckCircle />
                  Receive complete detailed billing via email
                </li>
                <li>
                  <CheckCircle />
                  Access your bill and pay online anytime
                </li>
                <li>
                  <CheckCircle />
                  Statements and payments are private and secure
                </li>
                <li>
                  <CheckCircle />
                  For further simplicity, enroll on Pre-authorized Debit and
                  avoid the hassle of making monthly payments
                </li>
              </StyledUL>
            </Column>
            <Column>
              <AlbertaEBillForm domain={siteUrl} brandToken={brandToken} />
            </Column>
          </Columns>
        </Section>
      </main>
    </Layout>
  )
}

EBillPage.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default EBillPage

export const query = graphql`
  query EBillPageQuery {
    file(name: { eq: "Hero-Green-Energy" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
        brandToken
      }
    }
  }
`
